/** @format */

import React, { useEffect, useState } from 'react';
import styles from '../css/blog-css-modules.module.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export const BlogPost = ({ name, body, image, createdDt, apiUrl }) => {
    var showdown = require('showdown'),
        converter = new showdown.Converter();

    const [index, setIndex] = useState(0);
    const [images, setImages] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const matches = [...body.matchAll(/\<img.+?\>/g)];
        const imageSrcs = matches.map((x) => x[0].replace('<img src="', '').replace('">', ''));
        setImages(imageSrcs);
    }, []);

    const clickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!images || images.length < 0) return;

        const { target } = e || {};
        const { src, tagName } = target || {};
        if (tagName.toLowerCase() !== 'img' || !src) return;

        const imageIndex = images.findIndex((x) => {
            if (x.includes(src)) return true;
            return false;
        });

        if (imageIndex !== -1) openLightBox(imageIndex);
        return;
    };

    const openLightBox = (i) => {
        setIndex(i);
        setIsOpen(true);
    };

    return (
        <>
            <div className={styles.blogStandard}>
                <div className={styles.itemPost}>
                    {image && (
                        <div className={styles.imgPost}>
                            <img src={apiUrl + image} alt="" className="banner" />
                            <span className={styles.iconPostType}>
                                <i className="fa fa-image"></i>
                            </span>
                        </div>
                    )}
                    <div className={styles.infoPost}>
                        <h5>
                            <a href="#">{name}</a>
                        </h5>
                        <ul className={styles.metaPost}>
                            <li>
                                On : <span className={styles.date}>{createdDt}</span>
                            </li>
                        </ul>
                        <div onClick={clickHandler} dangerouslySetInnerHTML={{ __html: converter.makeHtml(body) }} />
                    </div>
                </div>
            </div>
            {isOpen && images && (
                <Lightbox
                    mainSrc={images[index]}
                    nextSrc={images[(index + 1) % images.length]}
                    prevSrc={images[(index + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setIndex((index + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setIndex((index + 1) % images.length)}
                />
            )}
        </>
    );
};
