/** @format */

import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import styles from '../css/blog-css-modules.module.css';
import SEO from '../components/SEO';
import Banner from '../components/Banner';
import Layout from '../components/layout/Layout';
import Pagination from '../components/Pagination';
import { Row, SectionWrapper, SectionInnerWrapper } from '../components/Wrapper';
import { BlogPost } from '../components/BlogPost';
// import { Gallery } from '../components/Gallery';

const itemsPerPage = 5;

const BlogPage = ({ data }) => {
    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        setPageCount(Math.ceil(data.allStrapiBlog.edges.length / itemsPerPage));
    }, []);

    return (
        <Layout>
            <SEO title={`All Ins Agency - Blog`} description={`All Ins Agency`} />
            <Banner
                heading={data.allStrapiBanner.edges[0].node.heading}
                subHeading={data.allStrapiBanner.edges[0].node.subheading}
                trail={data.allStrapiBanner.edges[0].node.name}
                trailLink="/updates"
                image={data.site.siteMetadata.apiUrl + data.allStrapiBanner.edges[0].node.fields.imageUrl}
            />
            <SectionWrapper>
                <SectionInnerWrapper>
                    {/* <div>
                        <Gallery
                            backgroundImage={
                                data.site.siteMetadata.apiUrl +
                                data.allStrapiBackgroundimage.edges[0].node.fields.imageUrl
                            }
                            images={data.allStrapiBloggallery.edges.map(
                                (x) => data.site.siteMetadata.apiUrl + x.node.fields.imageUrl
                            )}
                        />
                    </div> */}
                    <Row alignTop>
                        {data.allStrapiBlog.edges.length > 0 && (
                            <div className={styles.updatesContainer}>
                                <div className={styles.latestNews}>
                                    {data.allStrapiBlog.edges.map(
                                        (x, i) =>
                                            i >= offset &&
                                            i < offset + itemsPerPage && (
                                                <BlogPost
                                                    key={i}
                                                    name={x.node.name}
                                                    body={x.node.body.replace(
                                                        /\/uploads/g,
                                                        `${data.site.siteMetadata.apiUrl}/uploads`
                                                    )}
                                                    image={x.node.fields.imageUrl}
                                                    createdDt={x.node.created_at}
                                                    apiUrl={data.site.siteMetadata.apiUrl}
                                                />
                                            )
                                    )}
                                </div>
                                <Pagination pageCount={pageCount} itemsPerPage={itemsPerPage} setOffset={setOffset} />
                            </div>
                        )}
                    </Row>
                </SectionInnerWrapper>
            </SectionWrapper>
        </Layout>
    );
};

export default BlogPage;

export const blogQuery = graphql`
    query BlogQuery {
        allStrapiBanner(filter: { page: { eq: "/updates" } }, sort: { fields: fields___sortindexnum, order: ASC }) {
            edges {
                node {
                    subheading
                    heading
                    name
                    page
                    fields {
                        imageUrl
                        sortindexnum
                    }
                }
            }
        }
        allStrapiBlog(filter: { published: { eq: true } }, sort: { fields: created_at, order: DESC }) {
            edges {
                node {
                    name
                    body
                    created_at(formatString: "DD MMM YYYY")
                    updated_at(formatString: "DD MMM YYYY")
                    fields {
                        imageUrl
                    }
                }
            }
        }
        site {
            siteMetadata {
                apiUrl
            }
        }
    }
`;
